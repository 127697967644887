.flip-container {
  float: left;
  perspective: 800px;
  transition: all 0.3s ease 0s;
  width: 30%;

  @media screen and (max-width: 900px) {
    width: 100%;
    margin-bottom: 16px;
  }
}

.flip-container:hover .card {
  transform: rotateY(180deg);
}

.card {
  height: 274px;
  transform-style: preserve-3d;
  transition: transform 0.4s ease 0s;
  width: 100%;
  -webkit-animation: giro 1s 1;
  animation: giro 1s 1;

  @media screen and (max-width: 900px) {
    height: 210px;
  }
 }

.front, .back {
  display: flex;
  backface-visibility: hidden;
  box-sizing: border-box;
  height: 100%;
  position: absolute;
  width: 100%;

  @media screen and (max-width: 900px) {
    margin-bottom: 16px;
  }
}

.back {
  transform: rotateY( 180deg);
}

@keyframes giro {
  from {
    transform: rotateY( 180deg);
  }
  to {
    transform: rotateY( 0deg);
  }
}
  
@-webkit-keyframes giro {
  from {
    transform: rotateY( 180deg);
  }
  to {
    transform: rotateY( 0deg);
  }
}